<script>
import draggable from 'vuedraggable'
import LegoTooltip from '@/components/Lego/LegoTooltip.vue';
import CardInColumn from '@/components/Lego/CardInColumn.vue'
import CreateCard from '@/components/Lego/CreateCard.vue'
import CreatorColumns from '@/components/Lego//CreatorColumns.vue'

export default {
  components: {
    draggable,
    LegoTooltip,
    CardInColumn,
    CreateCard,
    CreatorColumns
  },
  computed: {
    board() {
      return this.$store.state.lego.board
    },
    columns() {
      return this.board.columns
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    },

  },
  methods: {

    createColumn(column) {
      // Seria bueno agregar un loader congelando la vista hasta que se cree la columna.
      column.LegoBoardId = this.board.id
      console.log(column);
      this.$store.dispatch('lego/addColumn', column)
    },
    setTitle(ev, column) {
      const title = ev.target.value.trim()
      if (!title) return
      this.$store.dispatch('lego/setItemInColumn', {
        columnId: column.id,
        obj: { title }
      })
      ev.target.blur()
    },
    setColor(ev, column) {
      const color = ev.target.value.trim()
      if (!color) return
      this.$store.dispatch('lego/setItemInColumn', {
        columnId: column.id,
        obj: { color }
      })
    },
    onEndColumn(ev) {
      const { oldIndex, newIndex } = ev;
      let newColumns = [...this.columns]; // Clona la lista para evitar mutaciones directas
      const movedItem = newColumns.splice(oldIndex, 1)[0];
      newColumns.splice(newIndex, 0, movedItem);
      this.$store.dispatch('lego/setColumns', newColumns)
    },
    deleteColumn(column) {
      this.$store.dispatch('lego/deleteColumn', column)
    },
    createCard( card, column ) {
      card.LegoColumnId = column.id
      this.$store.dispatch('lego/addCard', card)
    },
    inputCardDraggable( cards, columnId ) {
      this.$store.dispatch('lego/moveCard', {cards, columnId})
    }
  },
  
}
</script>

<template>
    <div class="board">
      <draggable 
      class="wrapper-columns" 
      :group="{ name: 'columns' }" 
      animation="300" :columns="columns"
      @end="onEndColumn" 
      handle=".drag-handle">
        <div v-for="column in columns" :key="column.id" class="column drag-handle" :id="column.id">

          <header class="d-flex justify-content-between align-items-center ">
            <input class="column-title" type="text" :value="column.title" @keydown.enter="setTitle($event, column)"
              @blur="setTitle($event, column)">


            <!-- * control column -->
            <LegoTooltip position="bottom">
              <!-- TODO: darle focus redirectorio -->
              <!-- <span>Editar nombre</span> --> 
              <div class="wrapper-color">
                <label :for="column.id + 'color'">
                  <span>Color</span>
                </label>
                <input :id="column.id + 'color'" class="color" type="color" :value="column.color"
                  @blur="setColor($event, column)" />
              </div>
              <span class="text-lego-danger" @click="deleteColumn(column)">Eliminar columna</span>
            </LegoTooltip>

          </header>

          <draggable 
          :style="{ borderColor: column.color }" 
          class="wrapper-tasks" 
          ghost-class="bg-blue-200"
          @input="inputCardDraggable($event, column.id)"
          :value="column.cards"
          :group="{ name: 'cards' }"
          animation="300">
            <CardInColumn v-for="card in column.cards" :key="card.id" :card="card" :id="card.id"/>
          </draggable>

          <footer>
            <CreateCard @createCard="createCard($event, column)" v-if="roleLevel < 3" />
          </footer>
        </div>
      </draggable>

      <CreatorColumns @createColumn="createColumn" v-if="roleLevel < 3" />
    </div>
</template>

<style scoped>
.board {
  flex: 1;
  padding: 1rem;
  display: flex;
  overflow: auto;
  /* gap: 1rem; */
}

.board {
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  gap: 1rem;
}

.wrapper-columns {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.column {
  position: relative;
  padding: 1rem;
  width: 300px;
  border-radius: 11px;
  cursor: move;
  box-shadow: 0 0 4px 2px var( --lego-shadow);
  background-color: var(--lego-bg);
}

.column-title {
  background-color: transparent;
  letter-spacing: .25rem;
  border: 0;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  transition: .3s;
  color: white;
  padding: .5rem;
  border-radius: 11px;
}

.column-title:focus {
  background-color: var(--lego-light-hover);
  cursor: text;
}

.wrapper-tasks {
  border-top: .25rem solid;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.wrapper-color {
  padding: 0;
  position: relative;
}

.wrapper-color label {
  padding: .5rem 1rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.color {
  position: absolute;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  width: 0px;
  height: 0px;
  transform: translateY(-2px);
}
</style>
